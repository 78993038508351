import React from "react";
import Inquiryfirst from "../components/Web/Web-InquiryPage/Web-Inquiry";
import styled from "styled-components";



const Inquiry= ()=>{
    const InquiryPageComponent = styled.div`
        
    `;

    return(
        <InquiryPageComponent>
        <Inquiryfirst/>
        
        </InquiryPageComponent>

    )
}

export default Inquiry;